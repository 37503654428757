exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cfyc-tsx": () => import("./../../../src/pages/cfyc.tsx" /* webpackChunkName: "component---src-pages-cfyc-tsx" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stake-tsx": () => import("./../../../src/pages/stake.tsx" /* webpackChunkName: "component---src-pages-stake-tsx" */),
  "component---src-pages-staking-tsx": () => import("./../../../src/pages/staking.tsx" /* webpackChunkName: "component---src-pages-staking-tsx" */),
  "component---src-pages-swap-js": () => import("./../../../src/pages/swap.js" /* webpackChunkName: "component---src-pages-swap-js" */),
  "component---src-pages-zerolosspad-tsx": () => import("./../../../src/pages/zerolosspad.tsx" /* webpackChunkName: "component---src-pages-zerolosspad-tsx" */)
}

